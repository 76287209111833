import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class ValidatorService {
    constructor() { }

    noWhitespaceValidator(control: AbstractControl) {
        let REGEXP = new RegExp(/^\s*\s*$/);
        return !control.value || REGEXP.test(control.value) ? { required: true } : null;
    }

    onlyAlphabetsNumbersAndSpacesNotAllowed(c: FormControl) {
        let REGEXP = /^(?![0-9\s]*$)[a-zA-Z0-9\s]+$/;
        return REGEXP.test(c.value) ? null : { nonAlphaNumericValidator: true };
    }
validateIFSC(c: FormControl){
    let REGEXP = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    return REGEXP.test(c.value) ? null : { notValidIFSC: true };
}
    onlyAlphabetsAllowed(c: FormControl) {
        let REGEXP = /^[a-zA-Z]+$/;
        return REGEXP.test(c.value) ? null : { nonAlphaValidator: true };
    }
    nonNegativeValidator(c: FormControl): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value;
            if (value < 0) {
                return { nonNegativeValidator: true };
            }
            return null;
        };
    }

    emailValidator(c: FormControl) {
        //let REGEXP = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        let REGEXP = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
        return REGEXP.test(c.value) ? null : { notValidEmail: true };
    }

    phoneNumberValidator(c: FormControl) {
        let REGEXP = /^\d{10}$/;
        return REGEXP.test(c.value) ? null : { notValidPhoneNumber: true };
    }

    latitudeValidator(c: FormControl) {
        let REGEXP = /^(\+|-)?(?:90(?:(?:\.0{6,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{0,6})?))$/;
        return REGEXP.test(c.value) ? null : { latitudeValidator: true };
    }

    longitudeValidator(c: FormControl) {
        let REGEXP = /^(\+|-)?(?:180(?:(?:\.0{6,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{0,6})?))$/;
        return REGEXP.test(c.value) ? null : { longitudeValidator: true };
    }

    atLeastOneCheckboxCheckedValidator(formArr: AbstractControl): ValidationErrors | null {
        const filtered = (formArr as FormArray).value.filter((chk: any) => chk.selected);
        return filtered.length ? null : { hasError: true }
    }

    dateRangeValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
          const startDate = control.get('startDate')?.value;
          const endDate = control.get('endDate')?.value;
      
          if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
            return { dateRangeError: 'Start date cannot be greater than end date' };
          }
      
          return null;
        };
      }

}
