import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { LoginService } from './login.service';
import { StorageHelperService } from '../core/services/storage-helper.service';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CountdownComponent, CountdownConfig } from "ngx-countdown";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ValidatorService } from '../shared/services/validator.service';
import { AppToastService } from '../core/services/app-toast.service';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {

    @ViewChild('cd', { static: false }) public countdown!: CountdownComponent;
    countdownConfig: CountdownConfig = {
        leftTime: 0, // 24 hours in milliseconds
        demand: true,
        notify: 0,
    };

    private countdownInterval: any;
    private tokenExpirationTime: number = 0;

    rememberMe: boolean = false;
    isOtpSent: boolean = false;
    isResentOTP = false;
    resendOtpCount: number = 0;
    wrongOtpCount: number = 0;
    email: FormControl = new FormControl();
    // otp: FormControl = new FormControl();
    otp1: FormControl = new FormControl();
    otp2: FormControl = new FormControl();
    otp3: FormControl = new FormControl();
    otp4: FormControl = new FormControl();
    error: any;
    emailForm: any;


    constructor(
        private layoutService: LayoutService,
        private toasterService: AppToastService,
        private loginService: LoginService, 
        private fb: FormBuilder,
        private storageService: StorageHelperService, 
        private validatorService: ValidatorService,
        private router: Router,

    ) {
        this.otp1.setValidators([Validators.required]);
        this.otp2.setValidators([Validators.required]);
        this.otp3.setValidators([Validators.required]);
        this.otp4.setValidators([Validators.required]);
        if (this.storageService.getItemFromLocal("isLoggedIn")) {
            this.router.navigate(['/home']);
        }
    }

    ngOnInit(): void {
        debugger
        this.emailForm = this.fb.group({
            email: ['', []]  // Add validators if needed
        });
        this.initializeTimer();
    }

    ngOnDestroy(): void {
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
        }
    }

    initializeTimer(): void {
        const accessToken = this.storageService.getItemFromLocal('accessToken');
        if (accessToken) {
            this.tokenExpirationTime = this.getTokenExpirationTime();
            this.countdownConfig.leftTime = this.tokenExpirationTime - Date.now();
            this.startCountdown();
        }
    }

    startCountdown(): void {
        this.countdownInterval = setInterval(() => {
            const remainingTime = this.tokenExpirationTime - Date.now();
            if (remainingTime <= 0) {
                this.refreshToken();
            }
        }, 1000);
    }

    refreshToken(): void {
        const accessToken = this.storageService.getItemFromLocal('accessToken');
        const refreshToken = this.storageService.getItemFromLocal('refreshToken');

        if (refreshToken) {
            this.loginService.refreshToken(accessToken, refreshToken).subscribe({
                next: (response: any) => {
                    this.storageService.setItemToLocal('accessToken', response.token.accessToken);
                    this.storageService.setItemToLocal('refreshToken', response.token.refreshToken);
                    this.tokenExpirationTime = this.getTokenExpirationTime();
                    this.countdownConfig.leftTime = this.tokenExpirationTime - Date.now();
                    clearInterval(this.countdownInterval);
                    this.startCountdown();
                },
                error: (error) => {
                    console.error('Error refreshing token:', error);
                    this.router.navigate(['/login']); // Redirect to login on error
                }
            });
        }
    }

    getTokenExpirationTime(): number {
        const helper = new JwtHelperService();
        const token = this.storageService.getItemFromLocal('accessToken');
        const expirationDate = helper.getTokenExpirationDate(token);
        return expirationDate ? expirationDate.getTime() : Date.now();
    }

    handleTokenExpiration(): void {
        // Check if the access token is available
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        // If no access token or refresh token, it might mean the user is not authenticated
        if (!accessToken || !refreshToken) {
            console.log('No access token or refresh token found. User may not be authenticated.');
            return;
        }

        // Check if the access token is expired or about to expire
        const tokenExpirationTime = this.getTokenExpirationTime();

        if (Date.now() > tokenExpirationTime) {
            console.log('Access token expired. Refreshing...');
            this.refreshToken();  // Pass the tokens as arguments
        } else {
            console.log('Access token is still valid.');
        }
    }


    sendOtp() {
        this.otp1.reset();
        this.otp2.reset();
        this.otp4.reset();
        this.otp3.reset();
        if (this.emailForm.valid) {
            this.error = '';
            this.loginService.generateToken(this.emailForm.value.email.trim()).subscribe({
                next: (otpDetails: any) => {
                    this.isOtpSent = true;
                    if (this.isOtpSent) {
                        this.toasterService.showSuccess('OTP sent successfully');
                        this.isResentOTP = false;
                    }
                    this.storageService.setItemToLocal('userID', otpDetails.userID);
                    document.getElementById('otp1')?.focus();
                },
                error: (err) => {
                    this.error = err.error.errors.Email[0];
                },
            });
        }
    }


    handleTimerEvent(event: any) {
        if (event.left === 0 && this.resendOtpCount < 2) {
            this.isResentOTP = true;
        }
    }

    keyUp1(e: any) {
        if (e.target.value.length == 1 && e.key !== 'Backspace') {
            document.getElementById('otp2')?.focus();
        } else if (e.key === 'Backspace') {
            document.getElementById('otp1')?.focus();
        }
    }

    keyUp2(e: any) {
        if (e.target.value.length == 1 && e.key !== 'Backspace') {
            document.getElementById('otp3')?.focus();
        } else if (e.key === 'Backspace') {
            document.getElementById('otp1')?.focus();
        }
    }

    keyUp3(e: any) {
        if (e.target.value.length == 1 && e.key !== 'Backspace') {
            document.getElementById('otp4')?.focus();
        } else if (e.key === 'Backspace') {
            document.getElementById('otp2')?.focus();
        }
    }

    keyUp4(e: any) {
        if (e.key === 'Backspace') {
            document.getElementById('otp3')?.focus();
        }
    }

    /**
     * @description submit OTP to validate and login
     */
    submitOtp() {
        if (this.otp1.valid && this.otp2.valid && this.otp3.valid && this.otp4.valid) {
            const params = {
                userid: this.storageService.getItemFromLocal('userID'),
                otp: this.otp1.value.toString() + this.otp2.value.toString() + this.otp3.value.toString() + this.otp4.value.toString(),
            };
            this.loginService.validateOtp(params).subscribe({
                next: (loginDetails: any) => {
                    if (loginDetails) {
                        this.storageService.setItemToLocal('accessToken', loginDetails.accessToken);
                        this.storageService.setItemToLocal('refreshToken', loginDetails.refreshToken);
                        this.storageService.setItemToLocal('refreshTokenExpiry', loginDetails.refreshTokenExpiry);
                        this.storageService.setItemToLocal("isLoggedIn", loginDetails);
                        const helper = new JwtHelperService();

                        const decodedToken = helper.decodeToken(
                            loginDetails.accessToken
                        );

                        // Other functions
                        const expirationDate = helper.getTokenExpirationDate(
                            loginDetails.accessToken
                        );
                        const isExpired = helper.isTokenExpired(
                            loginDetails.accessToken
                        );
                        console.log(decodedToken, expirationDate, isExpired);
                        localStorage.setItem('Access', JSON.stringify(decodedToken.applicationclaims));
                        this.callApis();
                        this.router.navigate(['/home']);
                    }
                },
                error: (err) => {
                    if (!err.error.issuccess && err.error.statusmessage == 'Wrong OTP has been entered!') {
                        this.wrongOtpCount++;
                        if (this.wrongOtpCount == 2) {
                            this.isOtpSent = false;
                            this.toasterService.showError('Please login again.');
                            this.wrongOtpCount = 0;

                        }
                    }
                    this.otp1.reset();
                    this.otp2.reset();
                    this.otp4.reset();
                    this.otp3.reset();
                },
            });
        }
    }

    callApis() {
        const getAllRoles$ = this.loginService.getAllRoles();
        const getAllVenues$ = this.loginService.fetchAllVenuesByUser(
            this.storageService.getItemFromLocal("userID")
        );
        forkJoin([getAllRoles$]).subscribe({
            next: (res: any) => {
                this.storageService.setItemToLocal('allRoles', res[0]);
            },
        });
    }
    otpCount() {
        if (this.resendOtpCount < 2) {
            this.resendOtpCount++;
        }
    }
}
