import { Injectable } from "@angular/core";
import { MasterService } from "../core/services/master.service";
import { API_END_POINTS } from "../core/constants/app.apiEndPoints";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(private masterService: MasterService) { }

  generateToken(email: string) {
    const url = this.masterService.constructApiUrl(API_END_POINTS.Login);
    return this.masterService.post(
      url,
      { email: email, otpGenAttempt: 1 },
      undefined,
      {
        loader: false,
      }
    );
  }

  validateOtp(data: any) {
    const url = this.masterService.constructApiUrl(API_END_POINTS.VerifyOTP);
    
    // Construct the query parameters
    const queryParams = `?userId=${encodeURIComponent(data.userid)}&OtpToken=${encodeURIComponent(data.otp)}`;
  
    // Make the GET request with the query parameters
    return this.masterService.post(url + queryParams);
  }


  getAllRoles() {
    const url = this.masterService.constructApiUrl(API_END_POINTS.GetAllRoles);
    return this.masterService.get(url, undefined, { loader: false });
  }

  fetchAllVenuesByUser(userId: string) {
    const url = this.masterService.constructApiUrl(
      API_END_POINTS.GetAllVenuesByUser
    );
    return this.masterService.get(
      url,
      {
        params: {
          userid: userId,
        },
      },
      { loader: false }
    );
  }

  fetchAllVenues(userId: string) {
    const url = this.masterService.constructApiUrl(API_END_POINTS.GetAllVenues);
    return this.masterService.get(
      url,
      {
        params: {
          userid: userId,
        },
      },
      { loader: false }
    );
  }

  refreshToken(refreshToken: string, accessToken: string) {
    const url = this.masterService.constructApiUrl(API_END_POINTS.RefreshToken);  // Ensure you use the correct endpoint for refreshing tokens
    return this.masterService.post(
      url,
      { refreshToken: refreshToken, accessToken: accessToken }, // Only send refresh token
      undefined,
      {
        loader: false,
      }
    );
  }
}
